.App {
  text-align: center;
  background-color:black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  position: relative;
  width: 30%;
  margin: 10px;
  padding: 10px;
}


.container .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.fa {
  padding: 20px;
  font-size: 20px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  color: white;
}

.row {
  display: flex;
}

.column {
  flex: 33.33%;
  padding: 5px;
}


.button-container {
  position: absolute;
  display:inline-block;
  position:relative;
  bottom:1em;
  right:3em;
  background-color: white;
  color:white;
  text-transform:uppercase;
  padding:1em 1.5em;}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: black;
}

.App-link {
  color: black;
}

.navbar-custom { 
  background-color: black; 
} 

.button-custom {
  border-radius: 0;
  margin:10px;
  display: inline-block;
}

.h1{
  font:20px;
}